import IconButton from "./IconButton";
import {useEffect} from "react";

export default function Popup({className = '', onClose, active, children}) {

    useEffect(() => {
        const handleKeyUp = ({keyCode}) => {
            if(keyCode === 27) onClose();
        };

        window.addEventListener('keyup', handleKeyUp);

        return () => window.removeEventListener('keyup', handleKeyUp);

    }, [onClose]);

    return (
        <div
            className={`fixed top-0 left-0 w-full h-full flex px-4 accent-purple-800 pointer-events-none z-50 transition-all duration-300 ${active ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}`}>

            <div className="absolute top-0 left-0 w-full h-full bg-white/[0.85]" onClick={onClose}/>

            {active && (
                <div className={`relative m-auto w-full p-4 md:p-7 rounded-[35px] flex flex-col items-end ${className}`}>
                    <IconButton icon="close" onClick={onClose} className="shrink-0"/>

                    <div className="w-full flex-1">
                        {children}
                    </div>
                </div>
            )}
        </div>
    )
}