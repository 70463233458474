import Image from "next/image";
import Container from "./Container";

export default function Hero({direction = 'image-left', imageMobile, imageDesktop, narrow = false, className = '', mobileReverse = true, children}) {
    const mobileFlexClasses = mobileReverse ? 'flex-col-reverse' : 'flex-col';
    const heroClasses = direction === 'image-left' ? 'lg:flex-row-reverse' : 'lg:flex-row';
    const imageWrapperClasses = direction === 'image-left' ? '-ml-[5rem] lg:-ml-[10rem]' : '';

    return (
        <Container>
            <div className={`flex ${mobileFlexClasses} ${heroClasses} ${className}`}>
                <div className="flex-1 w-full">
                    {children}
                </div>
                <div className="flex-1 w-full text-0">
                    <div
                        className={`hidden lg:block relative w-[calc(100%+10rem)] ${narrow ? 'aspect-[2/1]' : 'aspect-[37/27]'} max-h-full rounded-[45px] overflow-hidden ${imageWrapperClasses}`}>
                        <Image placeholder="blur" src={imageDesktop} alt="" priority
                               layout="fill" objectFit="cover"/>
                    </div>

                    <div
                        className={`block lg:hidden relative w-[calc(100%+5rem)] aspect-[37/27] max-h-[500px] rounded-[45px] overflow-hidden ${imageWrapperClasses}`}>
                        <Image placeholder="blur" src={imageMobile} alt="" priority
                               layout="fill" objectFit="cover"/>
                    </div>
                </div>
            </div>
        </Container>
    )
}