import SvgArrowRight from "./svg/SvgArrowRight";
import SvgArrowLeft from "./svg/SvgArrowLeft";
import SvgClose from "./svg/SvgClose";
import SvgPlus from "./svg/SvgPlus";


const iconClasses = 'will-change-transform';
const iconTransitionClasses = 'transition-transform duration-200';

function getIcon(type) {
    switch (type) {
        case 'arrow-right':
            return <SvgArrowRight
                className={`${iconClasses} ${iconTransitionClasses} group-hover:translate-x-1.5`}
                width={19}
            />;
        case 'arrow-left':
            return <SvgArrowLeft
                className={`${iconClasses} ${iconTransitionClasses} group-hover:-translate-x-1.5`}
                width={19}
            />;
        case 'close':
            return <SvgClose className={`${iconClasses} `} width={19}/>;
        case 'plus':
            return <SvgPlus className={`${iconClasses} `} width={19}/>;
        default:
            return null;
    }
}

export default function IconButton({icon, className = '', ...rest}) {
    const iconElement = getIcon(icon);

    return (
        <button
            className={`w-14 h-14 inline-flex items-center justify-center rounded-full bg-white/25 hover:bg-white/40 transition-colors duration-300 text-white group ${className}`}
            {...rest}
        >
            {iconElement}
        </button>
    )
}