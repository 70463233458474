import Image from "next/image";
import imageThumbnail from "../../public/img/video_thumbnail.jpg";
import Container from "../Container";
import PlayButton from "../PlayButton";
import {useEffect, useState} from "react";
import Popup from "../Popup";
import {useRouter} from "next/router";
import localized from "../../locale/locales";

export default function SectionVideo({smallPadding = false, children}) {
    const {locale} = useRouter();
    const {sections: {video: lang}} = localized[locale];

    const [activePopup, setActivePopup] = useState(false);

    const handlePlayVideo = () => {
        setActivePopup(true);
    }

    useEffect(() => {
        const handleKeyUp = ({keyCode}) => {
            if (keyCode === 27) setActivePopup(false);
        }

        activePopup ? window.addEventListener('keyup', handleKeyUp) : window.removeEventListener('keyup', handleKeyUp)

        return () => window.removeEventListener('keyup', handleKeyUp)


    }, [activePopup]);

    return (
        <>
            <Popup onClose={() => setActivePopup(false)} active={activePopup}
                   className="bg-white bg-gradient-to-tr from-accent-color to-accent-color/50 max-w-[900px] gap-7"
            >
                <iframe id="ytplayer"
                        src="https://www.youtube.com/embed/C3pgqUp44fg?modestbranding=1&color=white&showinfo=0&showsearch=0&rel=0&iv_load_policy=3"
                        frameBorder="0" allowFullScreen
                        className="w-full aspect-video border-0 rounded-[20px] md:rounded-[35px]"
                        allow="autoplay"/>
            </Popup>
            <Container className="relative">
                <div className={`${smallPadding ? 'pt-7 pb-14' : 'py-20'} md:py-40 flex flex-col-reverse md:flex-row items-center gap-6 md:gap-12`}>
                    <div
                        className="relative aspect-[11/8] md:aspect-[65/47] w-full flex-[1.5] text-0 accent-purple-800"
                        onClick={handlePlayVideo}>
                        <Image src={imageThumbnail}
                               layout="fill"
                               objectFit="cover"
                               lazyBoundary="700px"
                               className="rounded-[40px]"
                               alt=""
                        />
                        <div
                            className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 bg-white rounded-full">
                            <PlayButton onClick={handlePlayVideo}/>
                        </div>
                    </div>

                    <div className="flex-1 flex flex-col gap-5 w-full">
                        {children}
                    </div>
                </div>
            </Container>
        </>
    )
}